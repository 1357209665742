$primary: #2e3192;
$tertiary: #eff0fa;

.service-page-service-card {
    --blur: 16px;
    --size: clamp(300px, 100vmin, 600px);
    width: var(--size);
    aspect-ratio: 4 / 3;
    position: relative;
    border-radius: 2rem;
    overflow: hidden;
    color: #000;
    transform: translateZ(0);
}

.card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(calc(1 + (var(--hover, 0) * 0.25))) rotate(calc(var(--hover, 0) * -5deg));
    transition: transform 0.2s;
}

.card__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $tertiary;
    display: grid;
    gap: 0.5ch;
    backdrop-filter: blur(var(--blur));
    align-content: center;
}

.card__action {
    position: absolute;
    bottom: 10%;
    right: 1.5rem;
    transform: translateY(50%) translateY(calc((var(--size) * 0.4))) translateY(calc(var(--hover, 0) * (var(--size) * -0.4)));
    background: purple;
    display: grid;
    place-items: center;
    border-radius: 1.9rem;
    background: $primary;
    transition: transform 0.2s;
}

.card__footer span:nth-of-type(1) {
    font-size: calc(var(--size) * 0.065);
}

.card__footer span:nth-of-type(2) {
    font-size: calc(var(--size) * 0.035);
}

.card:is(:hover, :focus-visible) {
    --hover: 1;
}

@media (max-width: 1400px) {
    .service-page-service-card {
        --blur: 16px;
        --size: clamp(300px, 100vmin, 500px);
        width: var(--size);
        aspect-ratio: 4 / 3;
        position: relative;
        border-radius: 2rem;
        overflow: hidden;
        color: #000;
        transform: translateZ(0);
    }

    .card__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(calc(1 + (var(--hover, 0) * 0.25))) rotate(calc(var(--hover, 0) * -5deg));
        transition: transform 0.2s;
    }

    .card__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $tertiary;
        display: grid;
        gap: 0.5ch;
        backdrop-filter: blur(var(--blur));
        align-content: center;
    }

    .card__action {
        position: absolute;
        bottom: 10%;
        right: 1rem;
        transform: translateY(50%) translateY(calc((var(--size) * 0.4))) translateY(calc(var(--hover, 0) * (var(--size) * -0.4)));
        background: purple;
        display: grid;
        place-items: center;
        border-radius: 1.9rem;
        background: $primary;
        transition: transform 0.2s;
    }

    .card__footer span:nth-of-type(1) {
        font-size: calc(var(--size) * 0.065);
    }

    .card__footer span:nth-of-type(2) {
        font-size: calc(var(--size) * 0.035);
    }

    .card:is(:hover, :focus-visible) {
        --hover: 1;
    }
}

@media (max-width: 1200px) {
    .service-page-service-card {
        --blur: 16px;
        --size: clamp(300px, 100vmin, 400px);
        width: var(--size);
        aspect-ratio: 4 / 3;
        position: relative;
        border-radius: 2rem;
        overflow: hidden;
        color: #000;
        transform: translateZ(0);
    }

    .card__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(calc(1 + (var(--hover, 0) * 0.25))) rotate(calc(var(--hover, 0) * -5deg));
        transition: transform 0.2s;
    }

    .card__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $tertiary;
        display: grid;
        gap: 0.5ch;
        backdrop-filter: blur(var(--blur));
        align-content: center;
    }

    .card__action {
        position: absolute;
        bottom: 10%;
        right: 1.5rem;
        transform: translateY(50%) translateY(calc((var(--size) * 0.4))) translateY(calc(var(--hover, 0) * (var(--size) * -0.4)));
        background: purple;
        display: grid;
        place-items: center;
        border-radius: 1.9rem;
        background: $primary;
        transition: transform 0.2s;
    }

    .card__footer span:nth-of-type(1) {
        font-size: calc(var(--size) * 0.065);
    }

    .card__footer span:nth-of-type(2) {
        font-size: calc(var(--size) * 0.035);
    }

    .card:is(:hover, :focus-visible) {
        --hover: 1;
    }
}